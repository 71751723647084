const ApiDriver = require("./driver.js");

const API_HOST = "api.assessment.nigrivhub.com.local";

module.exports = class API {

    _driver;

    constructor() {
        this._driver = new ApiDriver({ host: API_HOST });
    }
    
    ActualPowerOfRules() {
        let url = this._driver.opaqueURL("/rules/reports/total-power");

        return this._driver.sendGet({
            endpoint: url.toString(),
        });
    }

    ActualPowerOfTests() {
        let url = this._driver.opaqueURL("/tests/reports/total-power");

        return this._driver.sendGet({
            endpoint: url.toString(),
        });
    }

    AssignTopicInput = ["id_schedule", "id_topic", "way"];
    AssignTopic({ id_schedule, id_topic, way }) {
        let url = this._driver.opaqueURL("/assigned-topics/by-schedule");
        return this._driver.sendPut({
            data: { id_schedule, id_topic, way },
            endpoint: url.toString(),
        });
    }

    CreateAssignedTopicsInput = ["id_assigned_topic", "id_topic", "id_schedule", "way"];
    CreateAssignedTopics({ id_assigned_topic, id_topic, id_schedule, way }) {
        let url = this._driver.opaqueURL("/assigned-topics");
        return this._driver.sendPost({
            data: { id_assigned_topic, id_topic, id_schedule, way },
            endpoint: url.toString(),
        });
    }

    DeleteAssignedTopicsInput = ["id_assigned_topic", "id_topic", "id_schedule", "way"];
    DeleteAssignedTopics({ id_assigned_topic, id_topic, id_schedule, way }) {
        let url = this._driver.opaqueURL("/assigned-topics");
        return this._driver.sendDelete({
            data: { id_assigned_topic, id_topic, id_schedule, way },
            endpoint: url.toString(),
        });
    }

    ListAssignedTopicsInput = ["id_assigned_topic", "id_topic", "id_schedule", "way"];
    ListAssignedTopics({ id_assigned_topic, id_topic, id_schedule, way }) {
        let url = this._driver.opaqueURL(["", "assigned-topics"].join("/"));
        if (id_assigned_topic) url.searchParams.set("id_assigned_topic", id_assigned_topic);
        if (id_topic) url.searchParams.set("id_topic", id_topic);
        if (id_schedule) url.searchParams.set("id_schedule", id_schedule);
        if (way) url.searchParams.set("way", way);

        return this._driver.sendGet({
            endpoint: url.toString(),
        });
    }

    UpdateAssignedTopicsInput = ["where", "to"];
    UpdateAssignedTopics({ where, to }) {
        let url = this._driver.opaqueURL("/assigned-topics");
        return this._driver.sendPut({
            data: { where, to },
            endpoint: url.toString(),
        });
    }

    AzureAuth() {
        let url = this._driver.opaqueURL("/auth/azure");

        return this._driver.sendGet({
            endpoint: url.toString(),
        });
    }

    CreateCompanyInput = ["id_company", "name", "is_production", "created_by"];
    CreateCompany({ id_company, name, is_production, created_by }) {
        let url = this._driver.opaqueURL("/companies");
        return this._driver.sendPost({
            data: { id_company, name, is_production, created_by },
            endpoint: url.toString(),
        });
    }

    DeleteCompanyInput = ["id_company", "name", "is_production", "created_by"];
    DeleteCompany({ id_company, name, is_production, created_by }) {
        let url = this._driver.opaqueURL("/companies");
        return this._driver.sendDelete({
            data: { id_company, name, is_production, created_by },
            endpoint: url.toString(),
        });
    }

    ListCompanyInput = ["id_company", "name", "is_production", "created_by"];
    ListCompany({ id_company, name, is_production, created_by }) {
        let url = this._driver.opaqueURL(["", "companies"].join("/"));
        if (id_company) url.searchParams.set("id_company", id_company);
        if (name) url.searchParams.set("name", name);
        if (is_production) url.searchParams.set("is_production", is_production);
        if (created_by) url.searchParams.set("created_by", created_by);

        return this._driver.sendGet({
            endpoint: url.toString(),
        });
    }

    UpdateCompanyInput = ["where", "to"];
    UpdateCompany({ where, to }) {
        let url = this._driver.opaqueURL("/companies");
        return this._driver.sendPut({
            data: { where, to },
            endpoint: url.toString(),
        });
    }

    DeassignTopicInput = ["id_schedule", "id_topic"];
    DeassignTopic({ id_schedule, id_topic }) {
        let url = this._driver.opaqueURL("/assigned-topics/by-schedule");
        return this._driver.sendDelete({
            data: { id_schedule, id_topic },
            endpoint: url.toString(),
        });
    }

    Index() {
        let url = this._driver.opaqueURL("/");

        return this._driver.sendGet({
            endpoint: url.toString(),
        });
    }

    ListCategories() {
        let url = this._driver.opaqueURL("/categories");

        return this._driver.sendGet({
            endpoint: url.toString(),
        });
    }

    ListTopicsAssignedToInput = ["id_schedule"];
    ListTopicsAssignedTo({ id_schedule }) {
        let url = this._driver.opaqueURL("/assigned-topics/by-schedule");
        return this._driver.sendPost({
            data: { id_schedule },
            endpoint: url.toString(),
        });
    }

    LoginInput = ["email", "password"];
    Login({ email, password }) {
        let url = this._driver.opaqueURL("/login");
        return this._driver.sendPost({
            data: { email, password },
            endpoint: url.toString(),
        });
    }

    Me() {
        let url = this._driver.opaqueURL("/me");

        return this._driver.sendGet({
            endpoint: url.toString(),
        });
    }

    CreateModuleInput = ["id_module", "name", "id_company", "state"];
    CreateModule({ id_module, name, id_company, state }) {
        let url = this._driver.opaqueURL("/modules");
        return this._driver.sendPost({
            data: { id_module, name, id_company, state },
            endpoint: url.toString(),
        });
    }

    CreateModuleDataInput = ["id_module_data", "id_module", "part", "key", "value"];
    CreateModuleData({ id_module_data, id_module, part, key, value }) {
        let url = this._driver.opaqueURL("/modules-data");
        return this._driver.sendPost({
            data: { id_module_data, id_module, part, key, value },
            endpoint: url.toString(),
        });
    }

    DeleteModuleDataInput = ["id_module_data", "id_module", "part", "key", "value"];
    DeleteModuleData({ id_module_data, id_module, part, key, value }) {
        let url = this._driver.opaqueURL("/modules-data");
        return this._driver.sendDelete({
            data: { id_module_data, id_module, part, key, value },
            endpoint: url.toString(),
        });
    }

    ListModuleDataInput = ["id_module_data", "id_module", "part", "key", "value"];
    ListModuleData({ id_module_data, id_module, part, key, value }) {
        let url = this._driver.opaqueURL(["", "modules-data"].join("/"));
        if (id_module_data) url.searchParams.set("id_module_data", id_module_data);
        if (id_module) url.searchParams.set("id_module", id_module);
        if (part) url.searchParams.set("part", part);
        if (key) url.searchParams.set("key", key);
        if (value) url.searchParams.set("value", value);

        return this._driver.sendGet({
            endpoint: url.toString(),
        });
    }

    UpdateModuleDataInput = ["where", "to"];
    UpdateModuleData({ where, to }) {
        let url = this._driver.opaqueURL("/modules-data");
        return this._driver.sendPut({
            data: { where, to },
            endpoint: url.toString(),
        });
    }

    DeleteModuleInput = ["id_module", "name", "id_company", "state"];
    DeleteModule({ id_module, name, id_company, state }) {
        let url = this._driver.opaqueURL("/modules");
        return this._driver.sendDelete({
            data: { id_module, name, id_company, state },
            endpoint: url.toString(),
        });
    }

    ListModuleInput = ["id_module", "name", "id_company", "state"];
    ListModule({ id_module, name, id_company, state }) {
        let url = this._driver.opaqueURL(["", "modules"].join("/"));
        if (id_module) url.searchParams.set("id_module", id_module);
        if (name) url.searchParams.set("name", name);
        if (id_company) url.searchParams.set("id_company", id_company);
        if (state) url.searchParams.set("state", state);

        return this._driver.sendGet({
            endpoint: url.toString(),
        });
    }

    UpdateModuleInput = ["where", "to"];
    UpdateModule({ where, to }) {
        let url = this._driver.opaqueURL("/modules");
        return this._driver.sendPut({
            data: { where, to },
            endpoint: url.toString(),
        });
    }

    PutModuleDataInput = ["part", "id_module", "data"];
    PutModuleData({ part, id_module, data }) {
        let url = this._driver.opaqueURL("/modules-data/bulk");
        return this._driver.sendPut({
            data: { part, id_module, data },
            endpoint: url.toString(),
        });
    }

    RegisterInput = ["email", "password"];
    Register({ email, password }) {
        let url = this._driver.opaqueURL("/register");
        return this._driver.sendPost({
            data: { email, password },
            endpoint: url.toString(),
        });
    }

    CreateRuleInput = ["id", "name", "chanceToOccur", "amountOfLosses", "riskValue", "calculatedState", "rule", "created_by"];
    CreateRule({ id, name, chanceToOccur, amountOfLosses, riskValue, calculatedState, rule, created_by }) {
        let url = this._driver.opaqueURL("/rules");
        return this._driver.sendPost({
            data: { id, name, chanceToOccur, amountOfLosses, riskValue, calculatedState, rule, created_by },
            endpoint: url.toString(),
        });
    }

    DeleteRuleInput = ["id", "name", "chanceToOccur", "amountOfLosses", "riskValue", "calculatedState", "rule", "created_by"];
    DeleteRule({ id, name, chanceToOccur, amountOfLosses, riskValue, calculatedState, rule, created_by }) {
        let url = this._driver.opaqueURL("/rules");
        return this._driver.sendDelete({
            data: { id, name, chanceToOccur, amountOfLosses, riskValue, calculatedState, rule, created_by },
            endpoint: url.toString(),
        });
    }

    ListRuleInput = ["id", "name", "chanceToOccur", "amountOfLosses", "riskValue", "calculatedState", "rule", "created_by"];
    ListRule({ id, name, chanceToOccur, amountOfLosses, riskValue, calculatedState, rule, created_by }) {
        let url = this._driver.opaqueURL(["", "rules"].join("/"));
        if (id) url.searchParams.set("id", id);
        if (name) url.searchParams.set("name", name);
        if (chanceToOccur) url.searchParams.set("chanceToOccur", chanceToOccur);
        if (amountOfLosses) url.searchParams.set("amountOfLosses", amountOfLosses);
        if (riskValue) url.searchParams.set("riskValue", riskValue);
        if (calculatedState) url.searchParams.set("calculatedState", calculatedState);
        if (rule) url.searchParams.set("rule", rule);
        if (created_by) url.searchParams.set("created_by", created_by);

        return this._driver.sendGet({
            endpoint: url.toString(),
        });
    }

    UpdateRuleInput = ["where", "to"];
    UpdateRule({ where, to }) {
        let url = this._driver.opaqueURL("/rules");
        return this._driver.sendPut({
            data: { where, to },
            endpoint: url.toString(),
        });
    }

    CreateScheduleInput = ["id_schedule", "id_company", "start_date", "address", "country", "working_days", "is_online", "possible_start_date", "possible_end_date", "created_by", "type", "contact", "note_for_auditor"];
    CreateSchedule({ id_schedule, id_company, start_date, address, country, working_days, is_online, possible_start_date, possible_end_date, created_by, type, contact, note_for_auditor }) {
        let url = this._driver.opaqueURL("/schedules");
        return this._driver.sendPost({
            data: { id_schedule, id_company, start_date, address, country, working_days, is_online, possible_start_date, possible_end_date, created_by, type, contact, note_for_auditor },
            endpoint: url.toString(),
        });
    }

    DeleteScheduleInput = ["id_schedule", "id_company", "start_date", "address", "country", "working_days", "is_online", "possible_start_date", "possible_end_date", "created_by", "type", "contact", "note_for_auditor"];
    DeleteSchedule({ id_schedule, id_company, start_date, address, country, working_days, is_online, possible_start_date, possible_end_date, created_by, type, contact, note_for_auditor }) {
        let url = this._driver.opaqueURL("/schedules");
        return this._driver.sendDelete({
            data: { id_schedule, id_company, start_date, address, country, working_days, is_online, possible_start_date, possible_end_date, created_by, type, contact, note_for_auditor },
            endpoint: url.toString(),
        });
    }

    ListScheduleInput = ["id_schedule", "id_company", "start_date", "address", "country", "working_days", "is_online", "possible_start_date", "possible_end_date", "created_by", "type", "contact", "note_for_auditor"];
    ListSchedule({ id_schedule, id_company, start_date, address, country, working_days, is_online, possible_start_date, possible_end_date, created_by, type, contact, note_for_auditor }) {
        let url = this._driver.opaqueURL(["", "schedules"].join("/"));
        if (id_schedule) url.searchParams.set("id_schedule", id_schedule);
        if (id_company) url.searchParams.set("id_company", id_company);
        if (start_date) url.searchParams.set("start_date", start_date);
        if (address) url.searchParams.set("address", address);
        if (country) url.searchParams.set("country", country);
        if (working_days) url.searchParams.set("working_days", working_days);
        if (is_online) url.searchParams.set("is_online", is_online);
        if (possible_start_date) url.searchParams.set("possible_start_date", possible_start_date);
        if (possible_end_date) url.searchParams.set("possible_end_date", possible_end_date);
        if (created_by) url.searchParams.set("created_by", created_by);
        if (type) url.searchParams.set("type", type);
        if (contact) url.searchParams.set("contact", contact);
        if (note_for_auditor) url.searchParams.set("note_for_auditor", note_for_auditor);

        return this._driver.sendGet({
            endpoint: url.toString(),
        });
    }

    UpdateScheduleInput = ["where", "to"];
    UpdateSchedule({ where, to }) {
        let url = this._driver.opaqueURL("/schedules");
        return this._driver.sendPut({
            data: { where, to },
            endpoint: url.toString(),
        });
    }

    SummaryOfProductionReadyRules() {
        let url = this._driver.opaqueURL("/rules/reports/production-ready");

        return this._driver.sendGet({
            endpoint: url.toString(),
        });
    }

    SummaryOfProductionReadyTests() {
        let url = this._driver.opaqueURL("/tests/reports/production-ready");

        return this._driver.sendGet({
            endpoint: url.toString(),
        });
    }

    SummaryOfTestsWaitingForReview() {
        let url = this._driver.opaqueURL("/tests/reports/waiting-for-review");

        return this._driver.sendGet({
            endpoint: url.toString(),
        });
    }

    CreateTestInput = ["id", "name", "tip", "created_by", "approved_by", "categories", "suggestedStates"];
    CreateTest({ id, name, tip, created_by, approved_by, categories, suggestedStates }) {
        let url = this._driver.opaqueURL("/tests");
        return this._driver.sendPost({
            data: { id, name, tip, created_by, approved_by, categories, suggestedStates },
            endpoint: url.toString(),
        });
    }

    DeleteTestInput = ["id", "name", "tip", "created_by", "approved_by", "categories", "suggestedStates"];
    DeleteTest({ id, name, tip, created_by, approved_by, categories, suggestedStates }) {
        let url = this._driver.opaqueURL("/tests");
        return this._driver.sendDelete({
            data: { id, name, tip, created_by, approved_by, categories, suggestedStates },
            endpoint: url.toString(),
        });
    }

    ListTestInput = ["id", "name", "tip", "created_by", "approved_by", "categories", "suggestedStates"];
    ListTest({ id, name, tip, created_by, approved_by, categories, suggestedStates }) {
        let url = this._driver.opaqueURL(["", "tests"].join("/"));
        if (id) url.searchParams.set("id", id);
        if (name) url.searchParams.set("name", name);
        if (tip) url.searchParams.set("tip", tip);
        if (created_by) url.searchParams.set("created_by", created_by);
        if (approved_by) url.searchParams.set("approved_by", approved_by);
        if (categories) url.searchParams.set("categories", categories);
        if (suggestedStates) url.searchParams.set("suggestedStates", suggestedStates);

        return this._driver.sendGet({
            endpoint: url.toString(),
        });
    }

    UpdateTestInput = ["where", "to"];
    UpdateTest({ where, to }) {
        let url = this._driver.opaqueURL("/tests");
        return this._driver.sendPut({
            data: { where, to },
            endpoint: url.toString(),
        });
    }

    CreateTopicInput = ["id_topic", "name", "category", "description", "ways"];
    CreateTopic({ id_topic, name, category, description, ways }) {
        let url = this._driver.opaqueURL("/topics");
        return this._driver.sendPost({
            data: { id_topic, name, category, description, ways },
            endpoint: url.toString(),
        });
    }

    DeleteTopicInput = ["id_topic", "name", "category", "description", "ways"];
    DeleteTopic({ id_topic, name, category, description, ways }) {
        let url = this._driver.opaqueURL("/topics");
        return this._driver.sendDelete({
            data: { id_topic, name, category, description, ways },
            endpoint: url.toString(),
        });
    }

    ListTopicInput = ["id_topic", "name", "category", "description", "ways"];
    ListTopic({ id_topic, name, category, description, ways }) {
        let url = this._driver.opaqueURL(["", "topics"].join("/"));
        if (id_topic) url.searchParams.set("id_topic", id_topic);
        if (name) url.searchParams.set("name", name);
        if (category) url.searchParams.set("category", category);
        if (description) url.searchParams.set("description", description);
        if (ways) url.searchParams.set("ways", ways);

        return this._driver.sendGet({
            endpoint: url.toString(),
        });
    }

    UpdateTopicInput = ["where", "to"];
    UpdateTopic({ where, to }) {
        let url = this._driver.opaqueURL("/topics");
        return this._driver.sendPut({
            data: { where, to },
            endpoint: url.toString(),
        });
    }

};